<template>
  <div></div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Dashboard',
    titleTemplate: '%s - Base OW'
  }
}
</script>
